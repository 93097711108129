/* sidebar */
.sidebar_items_originalSize {
  background-color: #f5f5f5;
  width: 28px;
  z-index: 1000;
  transition: 0.1s ease;
  position: fixed;
  left: 1em;
  border-radius: 0px;
}

.sidebar_items_originalSize_hover {
  background-color: #f5f5f5;
  width: 14.3em;
  z-index: 1000;
  transition: 0.1s ease;
  position: fixed;
  left: 1em;
  border-radius: 0px;
}

/* text classes */
.text {
  color: black;
  /* position: absolute; */
  overflow: hidden;
  left: 26%;
  white-space: nowrap;
  -webkit-transition: opacity 0.1s ease-in;
  -moz-transition: opacity 0.1s ease-in;
  -o-transition: opacity 0.1s ease-in;
  opacity: 0;
  margin-left: 1em;
}

.showTextOnHover {
  -webkit-transition: opacity 0.1s ease-in;
  -moz-transition: opacity 0.1s ease-in;
  -o-transition: opacity 0.1s ease-in;
  opacity: 1;
  color: black;
  overflow: hidden;
  left: 26%;
  white-space: nowrap;
  margin-left: 1em;
}

.icon_paddingTop {
  padding-top: 1em;
}

.center {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.paddingBottom {
  padding-bottom: 0.4em;
  height: 50px;
}

.paddingTop {
  padding-top: 0.4em;
  height: 50px;
}

.colorIcon {
  /* color: hsla(0, 0%, 100%, 0.8); */
  color: black;
}

.rowContent:hover {
  color: #000000;
  height: 50px;
}

.rowContent:hover .text {
  color: #000000;
}

.currentRouteColor {
  color: #7500C0;
  font-weight: 500;
}

.rowContent:hover .colorIcon {
  color: #000000;
}

.currentColorIcon {
  color: #7500C0;
}

/* fui and ssh styles options */

.collapse_row_size {
  width: 100%;
  color: #fff;
}

.sidebar_screenOptions_size {
  margin: 0 0 0.6em 1em;
  height: 30px;
  display: flex;
  align-items: center;
}

.subtext {
  /* color: hsla(0, 0%, 100%, 0.8); */
  color: black;
}

.subtext:hover {
  color: #000000;
}

.iconTransitionOpen {
  transform: rotate(180deg);
  transition: 0.3s ease;
}

.iconTransitionClose {
  transform: rotate(0deg);
  transition: 0.3s ease;
}

.testCarlos {
  width: 4em;
  height: 6em;
  position: relative;
  z-index: 1000;
}

.testCarlos:hover {
  width: 12.3em;
  transition: 0.1s ease;
}
.Toastify__toast--info {
    background: #A100FF;
    padding: 20px 16px 20px 22px;
    line-height: 22px;
}

.Toastify__toast--error {
    padding: 20px 16px 20px 22px;
    line-height: 22px;
}

._loading_overlay_content {
    top: 45%;
    left: 45%;
    position: fixed;
}


/* .tag {
    color: pink;
} */

.displayItems {
    align-items: center;
    margin-top: 2%;
}

.itemPieGraph {
    padding-left: -2px;
}

/* .cardItems {
 padding-left: 17px;
} */



/* @media only screen and (max-width: 1000px) {
    /* .tag {
        color: blue;
        display: flex;
        justify-content: flex-end;
    } */

    /* .itemPieGraph {
        display: none;
    } */

    /* .displayItems {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: -9%;
    } */

    /* .cardItems .itemPieGraph{
        margin-left: -15%;
    } */

    /* .itemPieGraph {
        margin-left: -9%;
    } */

    /* #piegraph {
        margin-left: -11%;
    } */
    

input[type="file"]::file-selector-button {
    border: 2px solid #7500C0;
    padding: 0.2em 0.4em;
    border-radius: 0px;
    background-color: #7500C0;
    transition: 1s;
}

input[type="file"]::file-selector-button:hover {
    background-color: #7500C0;
    border: 2px solid #7500C0;
}

.MuiStepIcon-root.MuiStepIcon-active {
    color: #7500C0 !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
    color: #7500C0 !important;
}

.MuiDialog-paperWidthSm {
    max-width: 800px !important;
}
