input[type="file"]::file-selector-button {
    border: 2px solid #7500C0;
    padding: 0.2em 0.4em;
    border-radius: 0px;
    background-color: #7500C0;
    transition: 1s;
}

input[type="file"]::file-selector-button:hover {
    background-color: #7500C0;
    border: 2px solid #7500C0;
}

.MuiStepIcon-root.MuiStepIcon-active {
    color: #7500C0 !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
    color: #7500C0 !important;
}

.MuiDialog-paperWidthSm {
    max-width: 800px !important;
}