.Toastify__toast--info {
    background: #A100FF;
    padding: 20px 16px 20px 22px;
    line-height: 22px;
}

.Toastify__toast--error {
    padding: 20px 16px 20px 22px;
    line-height: 22px;
}

._loading_overlay_content {
    top: 45%;
    left: 45%;
    position: fixed;
}


/* .tag {
    color: pink;
} */

.displayItems {
    align-items: center;
    margin-top: 2%;
}

.itemPieGraph {
    padding-left: -2px;
}

/* .cardItems {
 padding-left: 17px;
} */



/* @media only screen and (max-width: 1000px) {
    /* .tag {
        color: blue;
        display: flex;
        justify-content: flex-end;
    } */

    /* .itemPieGraph {
        display: none;
    } */

    /* .displayItems {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: -9%;
    } */

    /* .cardItems .itemPieGraph{
        margin-left: -15%;
    } */

    /* .itemPieGraph {
        margin-left: -9%;
    } */

    /* #piegraph {
        margin-left: -11%;
    } */
    
